import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { Section, Container, Columns } from 'react-bulma-components'

function ThankYouComponent() {
  return (
    <Section className="">
      <Container>
        <Columns className="is-centered">
          <Columns.Column className="is-8 is-12-mobile content">
            <h1 className="title ">Enquiry sent.</h1>
          </Columns.Column>
        </Columns>

        <div className="mt-3">
          <Columns className="is-inline-flex is-vcentered is-centered">
            <Columns.Column className="is-narrow">
              <figure className="image is-128x128">
                <StaticImage
                  src="https://www.datocms-assets.com/20955/1579120416-jenny.jpg?w=500&fit=facearea&facepad=5"
                  alt="Jenny - Pocketworks"
                  placeholder="tracedSVG"
                  loading="lazy"
                  className="is-rounded has-shadow"
                />
              </figure>
            </Columns.Column>
            <Columns.Column className="is-6">
              <p className="is-size-4 has-text-weight-book">
                "Thanks for the info, I'll be in touch shortly to see how we can
                help. Often within <b>20 minutes</b>."
              </p>
              <p className="is-medium pt-3">
                Jenny Turner, Sales &amp; Business Support
              </p>
            </Columns.Column>
          </Columns>
          <Columns className="is-centered mt-5">
            <Columns.Column className="is-4">
              <h3 className="title has-text-weight-semibold">
                As a thank you...
              </h3>
              <p className="has-text-weight-book is-size-4">
                You might like this visual guide for executives. It helps you
                understand the app development process and avoid common
                mistakes.
              </p>
              <Link
                to="https://www.datocms-assets.com/20955/1710402209-badly-drawn-mobile-v2-0.pdf"
                className="button is-primary is-leafy mt-5"
              >
                View the eBook now
              </Link>
              <p className="is-size-6 mt-3">You can also save for later</p>
            </Columns.Column>
            <Columns.Column className="is-4">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1721049641-pocketworks_mockup_badlydrawnmobilev2.png?fm=jpg&bg=FAF7F2&w=1200"
                alt="Pocketworks Team"
              />
            </Columns.Column>
          </Columns>
        </div>
      </Container>
    </Section>
  )
}

export default ThankYouComponent
