import React from 'react'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'

// Page Componnets
import ThankYouComponent from './components/thankYouComponent'

function Thanks() {
  return (
    <Layout>
      <SiteNav fullLogo />
      <ThankYouComponent />
    </Layout>
  )
}

export default Thanks
